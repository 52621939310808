<template>
  <div class="page-outter">
    <div class="drug-c-c">
      <div
        class="drug-c-item"
        v-for="(it,n) in drugList"
        :key="n"
      >
        <div>
          <img v-lazy="it.img" />
        </div>
        <div class="drug-c-dec">
          <div
            class="drug-label"
            @click="seeDetails(it)"
          >{{it.label}}</div>
          <div class="drug-mm">
            <span class="s1">{{it.price}}</span>
            <span class="s2">销量{{it.sales}}</span>
          </div>
          <div class="drug-submit">加入购物车</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drugs from "../curd/drugs";
export default {
  props: {
    typeName: String,
  },
  name: "drugList",
  data() {
    return {
      drugList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const type = this.$route.params.typeName;
      for (let i = 0; i < drugs.length; i++) {
        let ele = drugs[i];
        if (ele.type == type) {
          this.drugList = ele.data;
        }
      }
    },
    seeDetails(row) {
      const result = JSON.stringify(row);
      this.$router.push({
        path: "/drug-info/drugDetails",
        query: { res: result },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.drug-c-c {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.drug-c-item {
  position: relative;
  width: 220px;
  height: 387px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  img {
    width: 100%;
  }
  .drug-label {
    width: 180px;
    font-size: 13px;
    line-height: 1.5;
    margin: 0 auto;
    cursor: pointer;
  }
  .drug-mm {
    position: absolute;
    width: 200px;
    bottom: 64px;
    display: flex;
    justify-content: space-between;
    span {
      display: block;
    }
    .s1 {
      font-size: 23px;
      color: #e02020;
    }
    .s2 {
      font-size: 13px;
      margin-top: 10px;
      color: #838383;
    }
  }
  .drug-submit {
    position: absolute;
    width: 170px;
    border: 1px solid #fa6400;
    color: #fa6400;
    font-size: 13px;
    text-align: center;
    padding: 7px 13px;
    bottom: 26px;
    cursor: pointer;
  }
}
.drug-c-dec {
  padding: 2px 12px 12px 12px;
}
</style>